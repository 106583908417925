import React from "react"
import Navbar from "../components/navbar"
import SEO from "../components/SEO"
import { graphql } from "gatsby"
import PostLink from "../components/post-link"

const BlogPage = ({
  data: {
    allMarkdownRemark: { edges },
  }
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return (
    <main>
      <SEO title="Blog" pathname="/blog" />

      <Navbar></Navbar>

      <div className="lg:max-w-screen-md mx-auto p-6 lg:pt-32 pt-24 pb-16">
        <h1 className="lg:text-center tracking-tighter">
          <span className="text-pink">/</span>blog
        </h1>
        <p className="text-xl mb-16">
          I am not really a frequent writer. But when I do, they are mostly but
          not limited to about Product Design, Web Development,
          Entrepreneurship, Rants, and Random Thoughts. I also geek out here
          about things I am obsessed with.
        </p>

        {Posts}
      </div>
    </main>
  )
}

export default BlogPage

export const pageQuery = graphql`
         query {
           allMarkdownRemark(
             sort: { order: DESC, fields: [frontmatter___date] }
           ) {
             edges {
               node {
                 id
                 excerpt(pruneLength: 250)
                 frontmatter {
                   date(formatString: "MMMM DD, YYYY")
                   slug
                   title
                   topic
                 }
                 wordCount {
                   words
                 }
               }
             }
           }
         }
       `
