import React from "react"
import { Link } from "gatsby"
import Ert from "./ert"

const PostLink = ({ post }) => (
  <div>
    <Link to={post.frontmatter.slug} className="no-link-effect mb-8 text-gray hover:text-black transition-all duration-500">
      <h2 className="tracking-tight mb-2 leading-none text-black">
        {post.frontmatter.title}
      </h2>
      <p className="mb-3 text-black">
        <span className="text-pink uppercase font-semibold tracking-tighter">{post.frontmatter.topic}</span> · {post.frontmatter.date} · <Ert words={post.wordCount.words}></Ert>
      </p>
      <p>{post.excerpt}</p>
    </Link>
  </div>
)

export default PostLink